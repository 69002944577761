.LoadingScreen.MuiPaper-root {
    background-color: var(--dark-bg);
    box-sizing: border-box;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    font-size: calc(10px + 2vmin);
    color: var(--text-on-dark-bg);
    padding: 1rem;
    gap: 1rem;
    background-image: url('../assets/images/logo-sudmedia.svg');
    background-size: 20vmin;
    background-position: top 1rem left 1rem;
    background-repeat: no-repeat;
}

.LoginForm-dialog .MuiModal-backdrop {
    background: none;
}

.LoadingScreen header {
    flex-grow: 1;
    text-align: center;
    padding: 0 15vmin;
}

.LoadingScreen footer {
    text-align: right;
}

.LoadingScreen .connecting::before {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    margin-right: .2em;
    background-image: url('../assets/images/logo-react.svg');
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: center;
    animation: connect-logo-spin 10s linear infinite;
    translate: 0 .1em;
}

@media (prefers-reduced-motion: no-preference) {
    .LoadingScreen .connecting::before {
        animation: connect-logo-spin 4s linear infinite;
    }
}

@keyframes connect-logo-spin {
    from {
        rotate: 0deg;
    }

    to {
        rotate: 360deg;
    }
}
